import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

import { TranslatedText, loadTranslatableTitles } from "../../functions/translate";

const NotFoundPage = () => {

    const navigate = useNavigate();

    useEffect(()=>{
        loadTranslatableTitles(
            "Contenu introuvable",
            "Content not found");
    },[]);

    return (
        <div className="page">
            <div className="not-found-page">
                <div className="not-found-art">
                    <div>
                        <p>404</p>
                    </div>
                    <div></div>
                </div>
                <div className="not-found-section">
                    <div className="not-found-description">
                        <TranslatedText fr="Contenu introuvable" en="Content not found"/>
                    </div>
                    <div
                        className="not-found-btn"
                        onClick={()=>{navigate("/")}}>
                        <div>
                            <TranslatedText fr="Retour à la page d'accueil" en="Back to home page"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;