import { useEffect } from "react";

import { loadTranslatableTitles } from "../../../functions/translate";

const EagleWorkPage = () => {

    useEffect(()=>{
        loadTranslatableTitles(
            "Eagle work",
            "Eagle work");
    },[]);

    return (
        <div></div>
    );
};

export default EagleWorkPage;