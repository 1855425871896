import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { store } from "../functions/store";
import { setTitles } from './store/reducers/title';

const TranslatedText = ({fr, en}) => {
    const english = useSelector((state) => state.settings.english);
    return (<p>{english ? en : fr}</p>);
};

const TranslatedTitle = () => {
    const english = useSelector((state) => state.settings.english);
    const titles = useSelector((state) => state.title);
    useEffect(()=>{
        document.title = `Infrasolva${titles.en !== "" && titles.fr !== "" ? ' - ' : ''}${english ? titles.en : titles.fr}`;
    },[english, titles]);

    return (<></>);
}

const loadTranslatableTitles = (fr, en) => {
    store.dispatch(setTitles({fr: fr, en: en}));
};

export { TranslatedText, TranslatedTitle, loadTranslatableTitles };