import { useEffect } from "react";

import { loadTranslatableTitles } from "../../functions/translate";

const ContactPage = () => {

    useEffect(()=>{
        loadTranslatableTitles(
            "Contact",
            "Contact");
    },[]);

    return (
        <div></div>
    );
};

export default ContactPage;