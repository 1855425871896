import { configureStore } from "@reduxjs/toolkit";

import settingsSlice from './reducers/settings.js';
import titleSlice from './reducers/title.js';

export const store = configureStore({
    reducer: {
        settings: settingsSlice,
        title: titleSlice
    }
});