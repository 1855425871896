import { useEffect } from "react";

import { loadTranslatableTitles } from "../../functions/translate";

const CompanyPage = () => {

    useEffect(()=>{
        loadTranslatableTitles(
            "Entreprise",
            "Company");
    },[]);

    return (
        <div></div>
    );
};

export default CompanyPage;