import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    english: true
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.english = action.payload;
        }
    }
});

export const {
    setLanguage
} = settingsSlice.actions;

export default settingsSlice.reducer;