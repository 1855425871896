import { useEffect, useState } from "react";

import { TranslatedText, loadTranslatableTitles } from "../../functions/translate";

import { getCurrentVersion } from "../../functions/version";

const UnavailablePage = () => {

    const [version, setVersion] = useState("0.0.0.0");

    useEffect(()=>{
        getCurrentVersion()
        .then((result)=>{setVersion(result)});
        loadTranslatableTitles(
            "",
            "");
    },[]);

    return (
        <div className="unavailable-page">
            <div>
                <div className="unavailable-logo">
                    <div className="unavailable-animation">
                        <div className="crane-trolley">
                            <div></div>
                            <div></div>
                        </div>
                        <div className="logo-parts">
                            <div className="logo-i-part"></div>
                            <div className="logo-s-part">
                                <div></div>
                            </div>
                            <div className="logo-shape-part"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <TranslatedText
                        fr="Quelque chose de génial approche!"
                        en="Something great is coming!"/>
                </div>
            </div>
            <p className="unavailable-version-txt">{`Version ${version}`}</p>
        </div>
    );
};

export default UnavailablePage;