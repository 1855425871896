const getCurrentVersion = () => {
    return fetch("https://media.infrasolva.com/website/version.txt", {
        method: 'GET',
        cache: 'no-cache'
    })
    .then((result) => {
        if (result.ok){
            return result.text();
        }
        return "0.0.0.0";
    })
    .then((version) => {
        return version;
    });
};

export { getCurrentVersion };