import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

const UserMenu = () => {
    return (
        <div className="user-menu">
            <i><FontAwesomeIcon icon={faCircleUser}/></i>
        </div>
    );
};

export default UserMenu;