import { useEffect } from "react";

import { loadTranslatableTitles } from "../../functions/translate";

const HomePage = () => {

    useEffect(()=>{
        loadTranslatableTitles(
            "Solutions logistiques adaptées à vos besoins",
            "Logistics solutions tailored to your needs");
    },[]);

    return (
        <div></div>
    );
};

export default HomePage;