import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fr: "",
    en: ""
};

export const titleSlice = createSlice({
    name: 'title',
    initialState: initialState,
    reducers: {
        setTitles: (state, action) => {
            state.fr = action.payload.fr;
            state.en = action.payload.en;
        }
    }
});

export const {
    setTitles
} = titleSlice.actions;

export default titleSlice.reducer;