import { useState, useEffect } from "react";

import { store } from "../../functions/store";
import { setLanguage } from "../../functions/store/reducers/settings";

const LanguageMenu = () => {

    const [english, setEnglish] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        const language = navigator.language.substring(0, 2);
        store.dispatch(setLanguage(!(language === 'fr')));
    },[]);

    const setCurrentLanguage = (en) =>{
        store.dispatch(setLanguage(en));
        setEnglish(en);
        setOpen(false);
    }

    return (
        <div className="language-menu">
            <div onMouseOver={()=>{setOpen(true)}}>
                <div className={`languages ${english ? 'english': 'french'}`}></div>
            </div>
            <div>
                <div style={!open ? {maxHeight: '0px', padding: '0 20px'} : {}}>
                    <LanguageElement fr={true} setLanguage={setCurrentLanguage}/>
                    <LanguageElement fr={false} setLanguage={setCurrentLanguage}/>
                </div>
            </div>
        </div>
    );
};

const LanguageElement = ({fr, setLanguage}) => {
    return (
        <div className="language-element" onClick={()=>{setLanguage(!fr)}}>
            <div className={`languages ${fr ? 'french' : 'english'}`}></div>
            <p>{fr ? 'FR' : 'EN'}</p>
        </div>
    );
};

export default LanguageMenu;