import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { TranslatedText } from "../../functions/translate";

import Logo from "../logo/logo";
import UserMenu from "../userMenu/userMenu";
import LanguageMenu from "../languageMenu/languageMenu";

const Navbar = ({available}) => {

    const navigate = useNavigate();

    const [burgerActive, setBurgerActive] = useState(false);
    const [productMenuClosed, setProductMenuClosed] = useState(false);

    const goToPage = (newUrl) => {
        setBurgerActive(false);
        setProductMenuClosed(true);
        navigate(newUrl, { replace: true });
    };

    const isCurrentPage = (text) => {
            return window.location.pathname.includes(text);
    }

    return (
        <nav className={`main-nav ${!available && 'main-nav-not-visible'}`}>
            <div>
                <div
                    className="navbar-logo"
                    onClick={()=>{goToPage("/")}}><Logo/></div>
                <div className="navbar-languages"><LanguageMenu/></div>
                <div className="navbar-user"><UserMenu/></div>
                <div className={`burger-btn ${burgerActive && 'burger-btn-active'}`}
                    onClick={()=>{setBurgerActive(!burgerActive)}}>
                    <i><FontAwesomeIcon icon={
                        burgerActive ? faXmark : faBars
                    }/></i>
                </div>
                <div>
                    <ul className={`nav-elements ${!burgerActive && 'nav-closed'}`}>
                        <li
                            className="products-main-nav"
                            onMouseEnter={()=>{setProductMenuClosed(false)}}>
                            <TranslatedText fr='Produits' en='Products'/>
                            <div className={`products-nav ${productMenuClosed && 'products-nav-closed'}`}>
                                <div>
                                    <div onClick={()=>{goToPage("product/eagle-work")}}>
                                        <ProductMenuElement productTag="eagle-work" title="Eagle work" activePage={isCurrentPage('eagle-work')}/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li onClick={()=>{goToPage("company")}}>
                            <TranslatedText fr='Entreprise' en='Company'/>
                            <div className={`${isCurrentPage('company') && 'active-page'}`}></div>
                        </li>
                        <li onClick={()=>{goToPage("news")}}>
                            <TranslatedText fr='Nouvelles' en='News'/>
                            <div className={`${isCurrentPage('news') && 'active-page'}`}></div>
                        </li>
                        <li onClick={()=>{goToPage("contact")}}>
                            <TranslatedText fr='Contact' en='Contact'/>
                            <div className={`${isCurrentPage('contact') && 'active-page'}`}></div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

const ProductMenuElement = ({productTag, title, activePage}) => {
    return (
        <div className="product-element-of-nav">
            <div className={productTag}></div>
            <p>{title}</p>
            <div className={`${activePage && 'active-page-product'}`}></div>
        </div>
    );
}

export default Navbar;