import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Navbar from "./components/navbar/navbar";
import { TranslatedTitle } from './functions/translate';

import HomePage from './pages/home/home';
import EagleWorkPage from './pages/products/eagleWork/eagleWork';
import CompanyPage from './pages/company/company';
import NewsPage from './pages/news/news';
import ContactPage from './pages/contact/contact';
import NotFoundPage from './pages/notFound/notFound';
import UnavailablePage from './pages/unavailable/unavailable';

function App() {

  const [available, setAvailable] = useState(false);

  useEffect(()=>{
    setAvailable(process.env.NODE_ENV !== 'production');
  },[]);

  return (
    <div className="App">
      <BrowserRouter>
        <TranslatedTitle/>
        <Navbar available={available}/>
        <Routes>
          <Route index element={available ? <HomePage/> : <UnavailablePage/>}/>
          <Route path="product/eagle-work" element={available ? <EagleWorkPage/> : <Navigate to="/"/>}/>
          <Route path="company" element={available ? <CompanyPage/> : <Navigate to="/"/>}/>
          <Route path="news" element={available ? <NewsPage/> : <Navigate to="/"/>}/>
          <Route path="contact" element={available ? <ContactPage/> : <Navigate to="/"/>}/>
          <Route path="*" element={available ? <NotFoundPage/> : <Navigate to="/"/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

App.propTypes = {

}

export default App;
