import { useState, useEffect } from "react";

const Logo = () => {

    const [christmas, setChristmas] = useState(false);

    useEffect(()=>{
        setChristmas(false);
    },[]);

    return (
        <div className="main-logo">
            <div>
                <div>
                </div>
                <div>
                    <div className={`${christmas ? 'christmas' : 'standard'}-logo`}>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logo;